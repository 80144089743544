import Login from '../components/Login';
import Logout from '../components/Logout';

const LoginPage = () => {
    return (
        <div>
            <h1>Login page</h1>
            <Login />
            <Logout />
        </div>
    )
};

export default LoginPage;
