import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// Import your different view components
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import LoginPage from "./pages/Login";
import Logout from "./components/Logout";

import './App.css';

// Import the AuthProvider from your context
import { AuthProvider } from "./context/AuthContext";  // Adjust the path according to your folder structure

function App() {
  return (
    // Wrap the entire Router inside the AuthProvider
    <AuthProvider>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Logout />
              </li>
            </ul>
          </nav>

          <div style={{ paddingTop: '60px' }}>
          <Routes>
            {/* Define different routes for different views */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;