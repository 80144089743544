// /src/components/TokenInfo.js
import React from 'react';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode library
import './TokenInfo.css'; // Optional for styling

function TokenInfo() {
  // Get tokens from localStorage
  const idToken = localStorage.getItem('idToken');
  const accessToken = localStorage.getItem('accessToken');

  // Function to decode a token and return the payload
  const decodeToken = (token) => {
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (e) {
      console.error('Invalid token:', e);
      return null;
    }
  };

  const decodedIdToken = decodeToken(idToken);
  const decodedAccessToken = decodeToken(accessToken);

  return (
    <div className="token-info-box">
      <h3>Token Information</h3>

      {decodedIdToken ? (
        <>
          <h4>ID Token Payload:</h4>
          <pre>{JSON.stringify(decodedIdToken, null, 2)}</pre>
        </>
      ) : (
        <p>No valid ID token found</p>
      )}

      {decodedAccessToken ? (
        <>
          <h4>Access Token Payload:</h4>
          <pre>{JSON.stringify(decodedAccessToken, null, 2)}</pre>
        </>
      ) : (
        <p>No valid Access token found</p>
      )}
    </div>
  );
}

export default TokenInfo;
