import Greetings from '../components/Greetings';

const Home = () => {
    return(
        <div>
            <h1>Home Page 1</h1>;
            <Greetings />
        </div>
    )

}

export default Home;
