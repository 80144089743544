import React from 'react';
import { useAuth } from '../context/AuthContext'; // Use AuthContext for user state
import './Logout.css'; // Optional: Add styles for the logout button

function Logout() {
  const { user, signOut } = useAuth(); // Get the user and signOut function from the context

  // Only display the logout button if the user is logged in
  if (!user) return null;

  return (
    <button className="logout-button" onClick={signOut}>
      Logout
    </button>
  );
}

export default Logout;