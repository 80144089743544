import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from './awsConfig';  // Import the userPool from the awsConfig file

// Function to handle user sign-in
export const signInUser = (username, password) => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        // Store tokens in localStorage or sessionStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('refreshToken', refreshToken);

        resolve({ accessToken, idToken, refreshToken });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

// Function to refresh access token (if needed)
export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    return Promise.reject('No refresh token found');
  }

  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject('No current user found');
  }

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(cognitoUser.getRefreshToken(), (err, session) => {
      if (err) {
        reject(err);
      } else {
        const newAccessToken = session.getAccessToken().getJwtToken();
        localStorage.setItem('accessToken', newAccessToken);
        resolve(newAccessToken);
      }
    });
  });
};

// Function to sign out a user
export const signOutUser = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    localStorage.clear(); // Clear stored tokens
  }
};