import React from 'react';
import { useAuth } from '../context/AuthContext'; // Access user info from AuthContext
import './Greetings.css'; // Optional: Add styles for the greetings box

function Greetings() {
  const { user } = useAuth(); // Get the user from context

  return (
    <div className="greetings-box">
      {user ? (
        <h2>Hello, {user.name}! 👋</h2> // Greet user by name if logged in
      ) : (
        <h2>Hello, Guest! 👋</h2> // Generic greeting if not logged in
      )}
    </div>
  );
}

export default Greetings;