import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { signInUser } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import your styling
import TokenInfo from './TokenInfo';
import { jwtDecode } from 'jwt-decode';

function Login() {
  const { user, setUser } = useAuth(); // Get the user and setUser from AuthContext
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For redirecting after login

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { accessToken, idToken } = await signInUser(username, password);
      console.log('Logged in successfully!', accessToken, idToken);

      // Decode the ID token to get the user's name
      const decodedToken = jwtDecode(idToken);
      const userName = decodedToken.nickname;

      // Set the user in context after successful login
      setUser({ name: userName, idToken, accessToken });

      //navigate('/');
    } catch (err) {
      setError(err.message || 'Error logging in');
    } finally {
      setLoading(false);
    }
  };

  // If the user is already logged in, redirect or hide the login form
  if (user) {
    return <TokenInfo />
    // Optionally redirect immediately:
    // navigate('/'); // Uncomment this to automatically redirect to homepage
  }

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
          {error && <p className="login-error">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;
