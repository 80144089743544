import { CognitoUserPool } from 'amazon-cognito-identity-js';

// Cognito User Pool configuration
const poolData = {
  UserPoolId: 'eu-west-1_84ee7W0gU',
  ClientId: 'ro2f5lahu4i2le6higemkhabn',
};

// Export user pool for use in authService and other components
const userPool = new CognitoUserPool(poolData);

export default userPool;