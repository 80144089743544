import React, { createContext, useContext, useState, useEffect } from 'react';
import { signOutUser, refreshAccessToken } from '../services/authService';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentUser = localStorage.getItem('idToken');
    if (currentUser) {
      const decodedToken = jwtDecode(currentUser);
      setUser({
        name: decodedToken.nickname, // Extract the user's name from the token
        idToken: currentUser,
      });
      //refreshAccessToken();
    }
    setLoading(false);
  }, []);

  const signOut = () => {
    signOutUser();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, signOut }}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};